<template>
  <div class="structure" v-loading="loadContent">
    <organization-chart
      :datasource="structure"
      :zoom="true"
      :pan="true"
      
      @node-click="handleClick"
    >
      <template slot-scope="{ nodeData }">
        <div class="division_block">
          <img
            v-if="changeImage(nodeData.key) != false"
            :src="changeImage(nodeData.key)"
          />
          <div class="name-age">
            <div class="name-a">{{ nodeData.name }}</div>
            <div class="son-s">{{ nodeData.children_count }}</div>
          </div>
        </div>
      </template>
    </organization-chart>
  </div>
</template>
 
<script>
import "vue-orgchart/dist/style.min.css";
import OrganizationChart from "vue-organization-chart";
import "vue-organization-chart/dist/orgchart.css";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  components: { OrganizationChart },
  data() {
    return {
      loadContent: false,
    };
  },
  created() {
    this.handeFetch();
  },
  computed: {
    ...mapGetters({
      structure: "structure/structure",
    }),
  },
  methods: {
    ...mapActions({
      getCompanyStructure: "structure/getCompanyStructure",
    }),
    handeFetch() {
      if (this.$route.params.key == "companies") {
        this.getCompanyStructure(this.$route.params.id)
          .then((res) => {
              console.log(this.structure)
          })
          .catch((err) => {});
      }
      if (this.$route.params.key == "branches") {
      }
      if (this.$route.params.key == "departments") {
      }
    },
    handleClick: (child) => {
      console.warn(child);
    },
    changeImage(key) {
      if (key == "companies") {
        return "/img/icon/company.svg";
      }
      if (key == "branches") {
        return "/img/icon/branch.svg";
      }
      if (key == "departments") {
        return "/img/icon/department.svg";
      }
      return false;
    },
  },
};
</script>

<style  lang="scss">
.orgchart {
  background-image: unset !important;
  cursor: grab !important;
}
.structure {
  .node {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #22292f20;
    border-radius: 0.428rem;
    margin-bottom: 2rem;
    box-shadow: 0 4px 24px 0 #22292f1a;
    width: 220px;
    margin: 0px 10px;
    cursor: pointer;
  }
  .orgchart-container {
    position: relative;
    display: inline-block;
    height: calc(100vh - 135px);
    width: calc(100% - 0px);
    border: none;
  }
  .division_block {
    display: flex;
    padding: 10px 5px;
    justify-content: flex-start;
    align-items: center;
    img {
      object-fit: cover;
      width: 75px;
      height: 75px;
      border-radius: 50%;
    }
    .name-age {
      text-align: left;
      margin-left: 5px;
    }
    .name-a {
      font-size: 15px;
      color: #000000;
      margin-bottom: 5px;
      font-weight: bold;
    }
    .son-s {
      font-size: 13px;
      color: #b1b1b1;
    }
  }
}
</style>
